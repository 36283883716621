import marketplacePackage from '../assets/data/marketplace.json';
import productPackage from '../assets/data/products.json';
import assetsBullishIndexPackage from '../assets/data/assets_bullish_index.json';
import { MarketFeed } from '@app/lib/contracts';
import seedrandom from 'seedrandom';
import * as backend from '@app/lib/backend';

export const UPDATE_INTERVAL_MS = 1000 * 60 * 3; // 3 minutes

// export const UPDATE_INTERVAL_MS = 1000 * 30; // 3 minutes

// const NEXT_COMP_TS_IN_MS = 1719280800000 + 1 * 1000 * 60 * 60 * 24 * 7; //

// const ETH_ADDRESS_100: string[] = [
//   '0xb1b2e1f9aefc7a1d6f4b6c7c1a0d7b8c6f4b7e9f',
//   '0xa4a9c3d8e1e4f5b6c2d3a1f2b7d6e8f9b4e7c6d1',
//   '0xc2d4f6b7e8f9a1b4d3e7c6f4b1a2e1d3b6f4a9e8',
//   '0xe8f1b4c7d6a9e2b3d1c6f4b7a5e8f9c2d3b1f7a4',
//   '0xd3b7a2f4e1c6e8f9b4a1d6f2b3c7e9f1b6d4a5e7',
//   '0xa2e8c3d6b1f4e7f9b4d3a6c2f1b9d7e3a5b1c4f6',
//   '0xf9b1d3e8a7c6f4e1b3d4a2f7c1e6b9a5e3d2c8f4',
//   '0xb6d7c2a4f9e1f4d3b1c3a6e8f9b2d4a5c7e1f3a9',
//   '0xd1e8b4a6c7f3b1f9e4d2c3a5b7e9f6b1a4d3e2f8',
//   '0xc3d9b1a6f4e7e1d4f2b3a5c7b9f1e8a2d6e3b4f7',
//   '0xe8f6b3a7d2c9e1b4d3a5f1c7b9d6e4a2f3b1c8f7',
//   '0xa5d3e9b2c4f1b6e7a3d1c8f4b9d2e1a7f6c3b4e8',
//   '0xf1c7b4e9d3a2b6e4d7c1f3b9a5d2e8a6b1c3f4e7',
//   '0xd3a2f9b7e4c1e8d6b4f1c3b5a7e9f2d1a6b3e8c4',
//   '0xc4b1a6e9d3f2e8b3d7f1a5c2b9e4d1a7b6f3e8c5',
//   '0xe9f1b3d6c7a4e2b1d3a6f4c5b7e8d1a2f3b4c6e9',
//   '0xa6c9d3f1e8b4b7d2a1e3f6c5b9e4a2d1f7b3c8e6',
//   '0xf3b4e7d2c1a5e8d6b3f1a9c4b7e2d1a6f3c9b8e4',
//   '0xd4a1e8b3f7c2b6d9f3a5e1c4b8e7a2d3f6b1c5e9',
//   '0xc5b7e9d3a1f2e8d6b4f3a2c1b9e7a3d4f1b6c8e2',
//   '0xe9f1d4b3a7c2b6e3d1a5f8c4b1e7a2d6f3b9c5e8',
//   '0xa7d3e8c4b1f6b9d2a3f1e4c5b7e1a6d9f2b3c8e7',
//   '0xf2c1b9e8d3a5e7b4d1f3a6c2b8e9a1d4f7b3c5e6',
//   '0xd5a3e9c1b4f2e6b7d3a1f8c9b2e4a5d7f3b1c6e8',
//   '0xc7b4e8d1a6f9b3d2a5e1f3c2b9e6a4d3f7b8c1e5',
//   '0xe7f1d3b6a9c4b2e3d1a5f8c9b1e6a2d4f3b7c8e5',
//   '0xa8d3e7c2b4f6b9d1a5f3c1b7e4a9d2f8b3c6e1e9',
//   '0xf4c1b7e3d6a9e2b5d1f8a4b6e7c2d3a1f9b4e3c8',
//   '0xd6a5e9b3c2f7b1d4a3f1e8c9b7e1d2f5a4b3c8e6',
//   '0xc8b1e7d3a5f2b6e4d1f3a9c2b5e8a3d7f1b6e9c4',
//   '0xe5f3b1d4a7c2e8d6a9f1c3b9e2d1a6f7b3e4c1b8',
//   '0xa9d4e5c1b7f3e8b6d2a1f6c2b3e9a7d3f1b4e1c6',
//   '0xf5c2b8e4d1a7e3b9d6a1f9c3b7e2a4d8f1b6e9c5',
//   '0xd7a6e5b1c3f4b9d2e1a3f8c4b1e7d6a9f3b2e5c8',
//   '0xc9b3e6d1a5f2e7b4d3a1f6c1b8e9d2a4f7b5e3c7',
//   '0xe6f3b9d2a1c5e7b4d1f8a3b6e9c2d3a5f1b7e4c6',
//   '0xa3d5e1c7b9f4e8b2d3f1a6c9b5e4d2a1f7b3e6c8',
//   '0xf1c5b4e9d3a7e2b6d1a3f9c4b7e3d2a1f8b5e6c9',
//   '0xd8a1e3c5b7f2e6b9d1a4f3c2b8e7d9a5f1b6e4c3',
//   '0xc1b5e2d7a9f3e8b6d4a1f5c3b7e9d2a6f1b3e4c8',
//   '0xe7f4b1d6a5c2e3b9d1a8f3c5b1e2d7a6f9b3e8c4',
//   '0xa1d7e9c3b5f4e2b6d8a3f1c7b9e4d1a2f3b8e6c5',
//   '0xf6c3b9e7d1a4e5b2d8a1f3c9b1e2d4a7f5b6e8c2',
//   '0xd9a2e1c6b4f8e3b5d7a1f4c2b6e9d3a8f1b5e7c4',
//   '0xc6b1e5d2a8f3e7b4d9a2f1c3b9e6d8a5f4b7e2c1',
//   '0xe4f1b8d5a7c9e2b3d6a1f3c1b7e4d2a9f5b8e3c6',
//   '0xa2d8e4c1b5f9e3b7d6a5f1c8b9e2d7a3f4b1e6c5',
//   '0xf7c1b3e9d4a6e5b8d2a3f1c2b7e3d5a9f6b4e8c1',
//   '0xd8a4e1c9b6f3e7b5d2a1f4c3b8e9d1a7f2b6e5c3',
//   '0xc5b2e4d1a9f6e8b3d7a2f1c9b6e3d4a5f7b8e2c1',
//   '0xe2f3b1d5a7c4e8b6d9a3f1c1b7e4d6a2f8b9e3c5',
//   '0xa5d6e2c3b8f4e1b9d7a2f3c4b1e8d9a5f2b6e3c7',
//   '0xf3c2b6e8d1a5e9b7d3a4f1c6b9e2d8a3f5b1e7c4',
//   '0xd9a3e5c1b7f8e4b2d6a5f3c7b1e9d2a8f4b6e1c5',
//   '0xc2b7e4d1a8f6e9b3d5a2f1c5b8e3d7a9f2b1e6c4',
//   '0xe3f4b9d2a1c5e6b8d3a7f1c2b1e7d9a4f6b3e5c8',
//   '0xa1d4e3c9b5f2e8b7d1a6f3c3b9e1d5a2f8b6e4c7',
//   '0xf4c3b1e7d9a2e5b8d6a3f2c1b7e9d3a4f1b8e6c2',
//   '0xd6a2e9c4b3f1e5b1d8a7f2c9b6e3d1a5f4b3e8c7',
//   '0xc3b1e8d7a6f5e2b4d9a1f3c4b9e1d3a7f2b8e5c6',
//   '0xe1f3b5d2a4c7e9b6d8a3f1c2b8e7d4a5f9b3e6c1',
//   '0xa2d9e4c3b7f1e6b1d5a8f3c9b4e2d7a3f5b6e8c1',
//   '0xf1c4b9e2d3a7e6b5d8a1f3c1b7e9d4a2f8b3e5c6',
//   '0xd7a1e2c5b4f9e8b6d3a2f1c9b3e7d1a5f4b8e3c2',
//   '0xc5b7e3d1a9f2e4b6d8a3f1c7b2e9d5a1f6b3e4c8',
//   '0xe2f1b5d7a6c9e3b8d1a5f4c4b9e6d3a2f8b1e7c5',
//   '0xa3d1e5c2b6f9e7b4d3a1f2c9b8e1d4a7f5b3e6c4',
//   '0xf2c5b1e9d4a3e6b7d2a5f1c4b3e8d1a6f9b2e7c3',
//   '0xd3a7e1c9b4f8e2b5d1a6f3c1b7e4d9a3f5b2e6c8',
//   '0xc4b2e6d9a5f1e3b7d1a8f2c3b5e7d6a1f9b3e8c2',
//   '0xe8f1b4d3a5c7e2b6d9a1f3c1b8e4d2a9f5b7e3c6',
//   '0xa6d1e2c3b5f9e7b1d4a2f8c9b3e6d5a3f1b4e7c8',
//   '0xf9c1b3e7d2a4e8b5d1a6f2c3b7e1d9a5f4b8e3c6',
//   '0xd1a2e4c7b9f5e3b6d8a1f4c2b7e9d3a5f1b8e2c6',
//   '0xc6b3e1d7a2f8e4b9d5a1f3c1b7e2d9a4f6b8e3c5',
//   '0xe4f2b1d6a3c8e9b7d1a5f3c2b5e1d8a4f7b9e3c6',
//   '0xa5d3e7c4b1f8e2b6d1a3f9c7b2e4d6a1f5b3e9c8',
//   '0xf8c1b7e4d2a5e9b3d1a6f2c9b1e8d3a4f6b5e7c3',
//   '0xd3a5e9c1b4f6e7b2d8a1f4c3b9e5d2a7f1b8e6c4',
//   '0xc2b6e4d1a7f9e5b1d3a9f2c8b7e3d6a1f5b4e9c3',
//   '0xe5f3b1d8a2c7e4b9d6a1f3c5b8e2d7a9f4b1e6c8',
//   '0xa1d6e4c5b7f2e9b3d1a8f3c1b5e6d2a7f9b4e8c3',
//   '0xf3c7b1e2d9a4e5b8d3a1f6c2b9e4d7a5f8b3e1c6',
//   '0xd5a2e1c6b3f9e7b4d8a1f2c9b3e6d5a4f1b7e3c8',
//   '0xc7b1e5d4a2f3e6b8d1a9f5c3b7e1d6a4f2b9e8c5',
//   '0xe2f4b7d3a6c5e1b9d8a3f4c7b1e2d9a6f5b3e8c1',
//   '0xa3d7e1c5b9f6e2b8d4a1f3c9b2e5d1a7f4b6e3c8',
//   '0xf6c1b9e3d5a4e7b2d8a1f3c5b7e2d1a6f4b9e3c7',
//   '0xd8a1e4c6b3f9e5b7d2a3f1c2b6e8d4a9f3b1e7c5',
//   '0xc4b8e6d1a3f7e9b5d2a6f3c1b7e5d8a2f4b9e3c1',
//   '0xe7f1b4d6a2c5e9b8d3a1f3c4b7e2d5a9f6b1e8c2',
//   '0xa4d9e3c1b7f2e8b5d4a1f6c9b3e2d7a5f1b9e4c8',
//   '0xf1c4b3e7d5a6e2b9d1a8f4c7b2e9d3a5f6b1e8c3',
//   '0xd3a6e1c9b7f4e2b5d8a1f3c2b7e4d9a5f1b3e6c8',
//   '0xc9b1e5d4a3f8e7b6d2a5f3c1b7e9d3a4f2b8e1c6',
//   '0xe3f1b7d6a2c4e9b8d1a5f2c5b1e7d3a6f4b9e2c8',
//   '0xa7d1e9c4b5f3e6b2d1a8f5c7b3e4d2a9f1b6e8c3',
//   '0xf4c7b9e1d2a3e6b5d8a1f2c1b9e3d7a4f5b6e2c8',
//   '0xd5a1e7c3b8f6e2b9d4a5f1c9b7e1d2a6f3b4e9c5',
//   '0xc3b5e1d6a4f8e9b2d3a7f4c1b6e7d5a2f1b9e3c8',
//   '0xe2f7b1d4a9c6e3b8d1a5f7c5b1e9d2a4f6b3e8c7',
//   '0xa9d3e7c5b1f6e8b2d4a3f9c7b5e1d6a2f3b4e9c1',
//   '0xf2c1b5e3d9a8e6b3d7a4f1c9b7e5d2a1f8b4e3c6',
//   '0xd4a6e9c3b7f5e1b8d2a9f2c7b3e6d5a1f3b9e4c8',
//   '0xc6b2e1d9a3f4e5b8d7a1f9c3b5e2d4a6f8b1e7c3',
//   '0xe1f9b4d3a5c7e8b2d1a6f5c9b1e4d2a7f3b6e2c8',
//   '0xa2d7e5c9b1f8e3b6d4a5f3c1b9e7d2a4f6b3e1c7',
//   '0xf1c5b8e2d7a4e3b9d6a2f5c7b1e8d3a6f4b2e9c1',
//   '0xd9a2e1c6b4f3e7b8d1a5f9c2b6e4d7a1f3b5e8c9',
//   '0xc7b4e2d3a5f8e9b1d6a2f1c9b8e3d1a4f7b2e6c5',
//   '0xe3f5b9d1a2c8e4b7d3a6f2c1b9e5d7a3f4b8e2c6',
//   '0xa1d9e3c2b5f4e6b7d1a3f8c4b9e2d6a5f1b3e7c9',
// ];
//
// const NAME1 = ['hodling', 'whaling', 'diamond', 'degen', 'staking', 'defi', 'yielding', 'tokenized'];
//
// const NAME2 = [
//   'adam',
//   'alice',
//   'bruce',
//   'bethany',
//   'charlie',
//   'chloe',
//   'dylan',
//   'debra',
//   'edward',
//   'erica',
//   'frank',
//   'fiona',
//   'george',
//   'gina',
//   'harry',
//   'hannah',
//   'ian',
//   'isabel',
//   'johnny',
//   'jessica',
//   'kyle',
//   'kate',
//   'lucas',
//   'lucy',
//   'michael',
//   'megan',
//   'nathan',
//   'nancy',
//   'oliver',
//   'olivia',
// ];

export enum CB {
  dexSpot = 'dexSpot',
  dexPerps = 'dexPerps',
  cex = 'cex',
  bnbChain = 'bnbChain',
  ethChain = 'ethChain',
  opChain = 'opChain',
  uniswap = 'uniswap',
  oneinch = 'oneinch',
  synthetix = 'synthetix',
  binance = 'binance',
  okx = 'okx',
  solanaChain = 'solanaChain',
  jupiter = 'jupiter',
}

// dictionary of CBs with boolean values, to indicate whether or not the button is selected.
// begin with the default values of true.
export const INITIAL_SELECTED_CBS: { [key in CB]: boolean } = {
  dexSpot: true,
  dexPerps: true,
  cex: true,
  bnbChain: true,
  ethChain: true,
  opChain: true,
  uniswap: true,
  oneinch: true,
  synthetix: true,
  binance: true,
  okx: true,
  solanaChain: true,
  jupiter: true,
};

const CB_BY_LEVELS = {
  top: [CB.dexSpot, CB.dexPerps, CB.cex],
  chains: [CB.bnbChain, CB.ethChain, CB.opChain],
  dexes: [CB.uniswap, CB.oneinch, CB.synthetix],
  cexes: [CB.binance, CB.okx],
};

const CB_BY_CHILDREN: { [key in CB]: CB[] } = {
  dexSpot: [CB.bnbChain, CB.ethChain, CB.opChain],
  dexPerps: [CB.opChain],
  cex: [CB.binance, CB.okx],
  bnbChain: [CB.uniswap, CB.oneinch],
  ethChain: [CB.uniswap, CB.oneinch],
  opChain: [CB.uniswap, CB.synthetix, CB.oneinch],
  uniswap: [],
  oneinch: [],
  synthetix: [],
  binance: [],
  okx: [],
  solanaChain: [CB.jupiter],
  jupiter: [],
};

export async function getNextCompTs(): Promise<number> {
  // find the timestamp of the upcoming week
  const oneDay = 24 * 60 * 60 * 1000;
  const buffer = 2 * 60 * 60 * 1000;
  const oneWeek = 7 * oneDay;
  const now = Date.now();
  const nextCompTs = Number(await backend.getNextAvailableTimestamp()) * 1000 + oneDay + buffer;
  const delta = nextCompTs - now;
  const diff = delta % oneWeek;
  return now + diff;
}

export function toggleBinance(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.binance];
  if (current) {
    newSelectedCBs[CB.binance] = false;
  } else {
    newSelectedCBs[CB.binance] = true;
    newSelectedCBs[CB.cex] = true;
  }
  return newSelectedCBs;
}

export function toggleOkx(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.okx];
  if (current) {
    newSelectedCBs[CB.okx] = false;
  } else {
    newSelectedCBs[CB.okx] = true;
    newSelectedCBs[CB.cex] = true;
  }
  return newSelectedCBs;
}

export function toggleOneInch(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.oneinch];
  if (current) {
    newSelectedCBs[CB.oneinch] = false;
  } else {
    newSelectedCBs[CB.oneinch] = true;
    newSelectedCBs[CB.bnbChain] = true;
    newSelectedCBs[CB.ethChain] = true;
    newSelectedCBs[CB.opChain] = true;
    newSelectedCBs[CB.dexSpot] = true;
  }
  return newSelectedCBs;
}

export function toggleUniswap(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.uniswap];
  if (current) {
    newSelectedCBs[CB.uniswap] = false;
  } else {
    newSelectedCBs[CB.uniswap] = true;
    newSelectedCBs[CB.bnbChain] = true;
    newSelectedCBs[CB.ethChain] = true;
    newSelectedCBs[CB.opChain] = true;
    newSelectedCBs[CB.dexSpot] = true;
  }
  return newSelectedCBs;
}

export function toggleSynthetix(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.synthetix];
  if (current) {
    newSelectedCBs[CB.synthetix] = false;
  } else {
    newSelectedCBs[CB.synthetix] = true;
    newSelectedCBs[CB.opChain] = true;
    newSelectedCBs[CB.dexPerps] = true;
  }
  return newSelectedCBs;
}

export function toggleBnbChain(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.bnbChain];
  if (current) {
    newSelectedCBs[CB.bnbChain] = false;
    newSelectedCBs[CB.uniswap] =
      newSelectedCBs[CB.bnbChain] || newSelectedCBs[CB.ethChain] || newSelectedCBs[CB.opChain];
    newSelectedCBs[CB.oneinch] =
      newSelectedCBs[CB.bnbChain] || newSelectedCBs[CB.ethChain] || newSelectedCBs[CB.opChain];
  } else {
    newSelectedCBs[CB.bnbChain] = true;
    newSelectedCBs[CB.dexSpot] = true;
    newSelectedCBs[CB.uniswap] = true;
    newSelectedCBs[CB.oneinch] = true;
  }
  return newSelectedCBs;
}

export function toggleEthChain(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.ethChain];
  if (current) {
    newSelectedCBs[CB.ethChain] = false;
    newSelectedCBs[CB.uniswap] =
      newSelectedCBs[CB.bnbChain] || newSelectedCBs[CB.ethChain] || newSelectedCBs[CB.opChain];
    newSelectedCBs[CB.oneinch] =
      newSelectedCBs[CB.bnbChain] || newSelectedCBs[CB.ethChain] || newSelectedCBs[CB.opChain];
  } else {
    newSelectedCBs[CB.ethChain] = true;
    newSelectedCBs[CB.dexSpot] = true;
    newSelectedCBs[CB.uniswap] = true;
    newSelectedCBs[CB.oneinch] = true;
  }
  return newSelectedCBs;
}

export function toggleOpChain(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.opChain];
  if (current) {
    newSelectedCBs[CB.opChain] = false;
    newSelectedCBs[CB.uniswap] =
      newSelectedCBs[CB.bnbChain] || newSelectedCBs[CB.ethChain] || newSelectedCBs[CB.opChain];
    newSelectedCBs[CB.oneinch] =
      newSelectedCBs[CB.bnbChain] || newSelectedCBs[CB.ethChain] || newSelectedCBs[CB.opChain];
    newSelectedCBs[CB.synthetix] = newSelectedCBs[CB.opChain];
  } else {
    newSelectedCBs[CB.opChain] = true;
    newSelectedCBs[CB.dexSpot] = true;
    newSelectedCBs[CB.dexPerps] = true;
    newSelectedCBs[CB.uniswap] = true;
    newSelectedCBs[CB.oneinch] = true;
    newSelectedCBs[CB.synthetix] = true;
  }
  return newSelectedCBs;
}

export function toggleDexSpot(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.dexSpot];
  if (current) {
    newSelectedCBs[CB.dexSpot] = false;

    newSelectedCBs[CB.bnbChain] = newSelectedCBs[CB.dexSpot];
    newSelectedCBs[CB.ethChain] = newSelectedCBs[CB.dexSpot];
    newSelectedCBs[CB.opChain] = newSelectedCBs[CB.dexSpot] || newSelectedCBs[CB.dexPerps];

    newSelectedCBs[CB.uniswap] = false;
    newSelectedCBs[CB.oneinch] = false;
  } else {
    newSelectedCBs[CB.dexSpot] = true;
    newSelectedCBs[CB.bnbChain] = true;
    newSelectedCBs[CB.ethChain] = true;
    newSelectedCBs[CB.opChain] = true;

    newSelectedCBs[CB.uniswap] = true;
    newSelectedCBs[CB.oneinch] = true;
  }
  return newSelectedCBs;
}

export function toggleDexPerps(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.dexPerps];
  if (current) {
    newSelectedCBs[CB.dexPerps] = false;
    newSelectedCBs[CB.opChain] = newSelectedCBs[CB.dexPerps];
    newSelectedCBs[CB.synthetix] = newSelectedCBs[CB.opChain];
  } else {
    newSelectedCBs[CB.dexPerps] = true;
    newSelectedCBs[CB.opChain] = true;
    newSelectedCBs[CB.synthetix] = true;
  }
  return newSelectedCBs;
}

export function toggleCex(selectedCBs: { [key in CB]: boolean }): { [key in CB]: boolean } {
  const newSelectedCBs = { ...selectedCBs };
  const current = selectedCBs[CB.cex];
  if (current) {
    newSelectedCBs[CB.cex] = false;
    newSelectedCBs[CB.binance] = newSelectedCBs[CB.cex];
    newSelectedCBs[CB.okx] = newSelectedCBs[CB.cex];
  } else {
    newSelectedCBs[CB.cex] = true;
    newSelectedCBs[CB.binance] = true;
    newSelectedCBs[CB.okx] = true;
  }
  return newSelectedCBs;
}

function convertListToDatapoints(list: string[][], initialEquity = 1): DataPoint[] {
  const equityLine = [];
  let value = initialEquity;
  // list = list.slice(-66);

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const timestamp = new Date(item[0] + 'Z').getTime();
    value *= 1 + parseFloat(item[1]);
    equityLine.push({ timestamp: timestamp, value: (value - 1) * 100 });
  }

  // if (list.length == 288) {
  //   for (let i = 0; i < equityLine.length; i++) {
  //     console.log('>>>', {
  //       timestamp: equityLine[i].timestamp,
  //       value: equityLine[i].value,
  //       dataTimestamp: list[i][0],
  //       dataValue: list[i][1],
  //     });
  //   }
  // }

  return equityLine;
}

function convertChallengeListToCompetitionDatapoints(list: never[][], multiplier = 1): CompetitionDataPoint[] {
  const returnVals = [];
  let accumulator = 0;
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const cycle = parseInt(item[0]);
    const value = parseFloat(item[1]) * multiplier + accumulator;
    accumulator = value;
    returnVals.push({ cycle: cycle, value: value });
  }
  return returnVals;
}

function convertChallengeListToCompetitionDatapointsCumProd(list: never[][], multiplier = 1): CompetitionDataPoint[] {
  const returnVals = [];
  let accumulator = 1;
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const cycle = parseInt(item[0]);
    const value = (parseFloat(item[1]) + 1) * accumulator;
    accumulator = value;
    returnVals.push({ cycle: cycle, value: (value - 1) * multiplier });
  }
  return returnVals;
}

function convertHistoricalAllocationsList(list: never[][], latestCycle: number): Allocation[][] {
  return list.slice(0, latestCycle).map((item) => {
    return item.map((alloc) => {
      return {
        asset: alloc['asset'],
        percentage: Number(alloc['allocation']) * 100,
        gain: Number(alloc['gain']) * 100,
      } as Allocation;
    });
  });
}

export interface DataPoint {
  timestamp: number;
  value: number;
}

export interface CompetitionDataPoint {
  cycle: number;
  value: number;
}

export interface MarketplaceData {
  market: string;
  metamodel: string;
  product: number;
  returns: number;
  cagr: number;
  sharpe: number;
  calmar: number;
  maxDrawdown: number;
  name: string;
  strategy: string;
  h24: number;
  d7: number;
  d30: number;
  m3: number;
  y1: number;
  total: number;
  addedTimestamp: number;
  visitCount: number;
}

export interface ProductSharesPredictors {
  share: number;
  predictor: string;
}

export interface HistoricalReturns {
  '1d': DataPoint[];
  '1w': DataPoint[];
  '1m': DataPoint[];
  '3m': DataPoint[];
  '1y': DataPoint[];
  all: DataPoint[];
}

export interface Allocation {
  asset: string;
  percentage: number;
  gain: number;
}

export interface ProductData {
  market: string;
  metamodel: string;
  product: string;
  returns: number;
  cagr: number;
  sharpe: number;
  calmar: number;
  maxDrawdown: number;
  historicalReturns: HistoricalReturns;
  predictors: ProductSharesPredictors[];
  aiStage: number;
  yiedlStaked: number;
  rewardBurnRatio: number;
  name: string;
  strategy: string;
}

export interface ProductAllocationActions {
  asset: string;
  action: 'buy' | 'sell' | 'hold';
  usdValue: number;
  percentage: number;
}

export interface CompetitionData {
  competition: 'updown' | 'neutral';
  address: string;
  latestRelativeGain: number;
  rewardBurnRatio: number;
  currentStake: number;
  aiAge: number;
  historicalRewards: CompetitionDataPoint[];
  historicalRelativeGains: CompetitionDataPoint[];
  historicalAllocations: Allocation[][]; // array of array of allocations
  cagr: number;
}

export interface AggregatedData {
  updown: CompetitionDataPoint[];
  neutral: CompetitionDataPoint[];
  total: CompetitionDataPoint[];
}

export interface AggregatedAllocations {
  updown: Allocation[];
  neutral: Allocation[];
  total: Allocation[];
}

export const AGGREGATED_ALLOCATIONS: AggregatedAllocations = {
  updown: [],
  neutral: [],
  total: [],
};

export const AGGREGATED_GAINS_MM: AggregatedData = {
  updown: [],
  neutral: [],
  total: [],
};

export const AGGREGATED_GAINS_TOP10: AggregatedData = {
  updown: [],
  neutral: [],
  total: [],
};

export const AGGREGATED_GAINS_TOP25: AggregatedData = {
  updown: [],
  neutral: [],
  total: [],
};

export const AGGREGATED_PREDICTOR_COUNT: AggregatedData = {
  updown: [],
  neutral: [],
  total: [],
};

export const AGGREGATED_REWARDS: AggregatedData = {
  updown: [],
  neutral: [],
  total: [],
};

export const AGGREGATED_STAKES: AggregatedData = {
  updown: [],
  neutral: [],
  total: [],
};

function convertAggData(respData: any): AggregatedData {
  return {
    updown: respData['updown'].map((item: any) => ({
      cycle: Number(item['challenge']),
      value: Number(item['value']),
    })) as CompetitionDataPoint[],
    neutral: respData['neutral'].map((item: any) => ({
      cycle: Number(item['challenge']),
      value: Number(item['value']),
    })) as CompetitionDataPoint[],
    // sum element-wise updown and neutral for total
    total: respData['updown'].map((item: any, index: number) => ({
      cycle: Number(item['challenge']),
      value: Number(item['value']) + Number(respData['neutral'][index]['value']),
    })) as CompetitionDataPoint[],
  } as AggregatedData;
}

export async function getAggregatedData(): Promise<AggregatedData[]> {
  const res = await Promise.all([
    backend.getCompetitionHistoricalPredictorCounts(),
    backend.getCompetitionHistoricalStakes(),
    backend.getCompetitionHistoricalRewards(),
  ]);
  const aggPredCount = convertAggData(res[0]);
  const aggStakes = convertAggData(res[1]);
  const aggRewards = convertAggData(res[2]);

  return [aggPredCount, aggStakes, aggRewards, AGGREGATED_GAINS_MM, AGGREGATED_GAINS_TOP10, AGGREGATED_GAINS_TOP25];
}

export async function getAggregatedAllocations(): Promise<AggregatedAllocations> {
  return AGGREGATED_ALLOCATIONS;
}

export interface CompetitionTableData {
  competition: 'updown' | 'neutral';
  address: string;
  latestRelativeGain: number;
  rewardBurnRatio: number;
  currentStake: number;
  aiAge: number;
  totalRelativeGain: number;
  deltaStake: number;
  totalRewards: number;
  latestReward: number;
  cagr: number;
}

const COMPETITON_DATA: CompetitionData[] = [];

export const BTC_HISTORICAL: HistoricalReturns = {
  '1d': [],
  '1w': [],
  '1m': [],
  '3m': [],
  '1y': [],
  all: [],
};

function randomValue(mult = 1, offset = 0.42): number {
  return Math.random() * mult - offset;
}

export async function getLatestChallengeNumber(competition: string): Promise<number> {
  return 100;
}

// get deterministic random value based on input within a range
function deterministicRandomValue(seed: string, min: number, max: number): number {
  const hash = simpleHash(seed);
  return min + (hash % (max - min + 1));
}
const ASSETS: string[] = [
  'AAVE',
  'ADA',
  'ALGO',
  'APE',
  'APT',
  'ATOM',
  'AVAX',
  'AXS',
  'BAL',
  'BCH',
  'BLUR',
  'BNB',
  'BONK',
  'BTC',
  'C98',
  'CAKE',
  'COMP',
  'CRV',
  'CVX',
  'DAI',
  'DAO',
  'DOGE',
  'DOT',
  'DYDX',
  'EOS',
  'ETC',
  'ETH',
  'FET',
  'FIL',
  'FRAX',
  'FTM',
  'FXS',
  'GRT',
  'HAI',
  'HERO', // no quote from diadata
  'ICP',
  'IMX',
  'INJ',
  'LDO',
  'LINK',
  'LTC',
  'MATIC',
  'MAV',
  'MBOX', // no quote from diadata
  'MKR',
  'MOON',
  'NEAR',
  'OP',
  'PENDLE',
  'PEPE',
  'PERP',
  'PIT',
  'POLS',
  'PYTH',
  'QUACK', // no quote from diadata
  'RDNT',
  'RNDR',
  'RPL',
  'RUNE',
  'SFP', // no quote from diadata
  'SFUND',
  'SHIB',
  'SOL',
  'STG',
  'SUI',
  'SUSHI',
  'TIA',
  'TOKEN',
  'TRX',
  'UNI',
  'USDT',
  'XLM',
  'XMR',
  'XRP',
  'XTZ',
  'XVS',
  'YFI',
];

function generateAllocations(N: number, assets = ASSETS): Allocation[][] {
  function getRandomPercentage(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  function generateSet(assets: string[]): Allocation[] {
    const minAbsValue = 0.5;
    const numAllocations = assets.length;
    let remainingAbsPercentage = 100;
    const allocations: Allocation[] = [];

    for (let i = 0; i < numAllocations - 1; i++) {
      const maxAllocation = remainingAbsPercentage - (numAllocations - i - 1) * minAbsValue;
      let percentage = getRandomPercentage(minAbsValue, maxAllocation);

      // Randomly assign positive or negative sign
      if (Math.random() < 0.5) {
        percentage = -percentage;
      }

      allocations.push({
        asset: assets[i],
        percentage: percentage,
        gain: deterministicRandomValue(assets[i] + percentage.toString(), -1.6, 1.9),
      });

      remainingAbsPercentage -= Math.abs(percentage);
    }

    // The last allocation gets the remaining percentage to ensure the absolute sum is 100
    let lastPercentage = remainingAbsPercentage;
    if (Math.random() < 0.5) {
      lastPercentage = -lastPercentage;
    }

    allocations.push({
      asset: assets[numAllocations - 1],
      percentage: lastPercentage,
      gain: deterministicRandomValue(assets[numAllocations - 1] + lastPercentage.toString(), -1.6, 1.9),
    });

    return allocations;
  }

  const result: Allocation[][] = [];
  for (let i = 0; i < N; i++) {
    result.push(generateSet(assets));
  }

  return result;
}

export async function getCompetitionTableData(): Promise<CompetitionTableData[]> {
  const compData = await backend.getCompetitionTableData();
  const cd = compData.map((data: any) => {
    return {
      competition: data['competitionName'],
      address: data['address'],
      latestRelativeGain: Number(data['latestRelativeGain']) * 100,
      rewardBurnRatio: Number(data['rewardBurnRatio']),
      currentStake: Number(data['latestStake']),
      aiAge: Number(data['aiStage']),
      totalRelativeGain: Number(data['totalRelativeGain']) * 100,
      deltaStake: Number(data['deltaStake']),
      totalRewards: Number(data['totalReward']),
      latestReward: Number(data['latestReward']),
      cagr: Number(data['cagr']) * 100,
    };
  });

  return cd.sort((a: CompetitionTableData, b: CompetitionTableData) => b.totalRelativeGain - a.totalRelativeGain);
}

export async function getParticipantData(
  competitionName: string | undefined,
  address: string | undefined,
): Promise<CompetitionData | undefined> {
  if (!competitionName || !address) {
    return undefined;
  }

  const participantData = await backend.getParticipantData(competitionName, address);
  const latestCycle = participantData['historicalRewards'].length;
  const returnData: CompetitionData = {
    address: participantData['address'],
    competition: participantData['competitionName'],
    aiAge: Number(participantData['aiStage']),
    cagr: Number(participantData['cagr']) * 100,
    latestRelativeGain: Number(participantData['latestRelativeGain']) * 100,
    rewardBurnRatio: Number(participantData['rewardBurnRatio']),
    currentStake: Number(participantData['currentStake']),
    historicalRewards: convertChallengeListToCompetitionDatapoints(participantData['historicalRewards']),
    historicalRelativeGains: convertChallengeListToCompetitionDatapointsCumProd(
      participantData['historicalRelativeGains'],
      100,
    ),
    historicalAllocations: convertHistoricalAllocationsList(participantData['historicalAllocations'], latestCycle),
  };

  return returnData;
}

// export function initCompetitionData(): void {
//   const totalCycles = 99;
//   const size = (productPackage.length / 4) % 2 === 0 ? productPackage.length / 4 : productPackage.length / 4 + 1;
//   const competitionData: CompetitionData[] = [];
//   for (let i = 0; i < size; i++) {
//     const data = productPackage[i];
//     const updownOrNeutral = i < size / 2;
//     const historicalRewards: CompetitionDataPoint[] = [];
//     for (let j = 0; j < totalCycles; j++) {
//       historicalRewards.push({
//         cycle: j + 1,
//         value: deterministicRandomValue((data.yieldStaked + j).toString(), -30000, 100000) / 10,
//       });
//     }
//     const historicalRelativeGains: CompetitionDataPoint[] = [];
//     for (let j = 0; j < totalCycles; j++) {
//       historicalRelativeGains.push({
//         cycle: j + 1,
//         value: deterministicRandomValue((data.yieldStaked + j).toString(), -5000, 5000) / 1000,
//       });
//     }
//     const address = updownOrNeutral ? ETH_ADDRESS_100[i] : ETH_ADDRESS_100[i - size / 2];
//
//     competitionData.push({
//       competition: updownOrNeutral ? 'updown' : 'neutral',
//       address: address,
//       latestRelativeGain: deterministicRandomValue(address, -5000, 5000) / 1000,
//       rewardBurnRatio: data.rewardBurnRatio,
//       currentStake: data.yieldStaked,
//       historicalRewards: historicalRewards,
//       historicalRelativeGains: historicalRelativeGains,
//       historicalAllocations: generateAllocations(totalCycles),
//       aiAge: totalCycles + 1,
//       cagr: data.cagr,
//     });
//   }
//   COMPETITON_DATA.push(...competitionData);
//
//   // generate range for aggregated data
//   const agg_range = Array.from({ length: totalCycles }, (_, i) => i + 1);
//
//   const apc_updown = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: Math.round(randomValue(70, 0)),
//     };
//   });
//
//   const apc_neutral = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: Math.round(randomValue(70, 0)),
//     };
//   });
//
//   const apc_total = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: apc_updown[i - agg_range[0]].value + apc_neutral[i - agg_range[0]].value,
//     };
//   });
//
//   AGGREGATED_PREDICTOR_COUNT.updown = apc_updown;
//   AGGREGATED_PREDICTOR_COUNT.neutral = apc_neutral;
//   AGGREGATED_PREDICTOR_COUNT.total = apc_total;
//
//   const ag_updown = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(5, 5 / 2),
//     };
//   });
//   const ag_neutral = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(5, 5 / 2),
//     };
//   });
//   const ag_total = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: (ag_updown[i - agg_range[0]].value + ag_neutral[i - agg_range[0]].value) / 2,
//     };
//   });
//   AGGREGATED_GAINS_MM.updown = ag_updown;
//   AGGREGATED_GAINS_MM.neutral = ag_neutral;
//   AGGREGATED_GAINS_MM.total = ag_total;
//
//   const agt10_updown = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(5, 5 / 2),
//     };
//   });
//   const agt10_neutral = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(5, 5 / 2),
//     };
//   });
//   const agt10_total = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: (agt10_updown[i - agg_range[0]].value + agt10_neutral[i - agg_range[0]].value) / 2,
//     };
//   });
//   AGGREGATED_GAINS_TOP10.updown = agt10_updown;
//   AGGREGATED_GAINS_TOP10.neutral = agt10_neutral;
//   AGGREGATED_GAINS_TOP10.total = agt10_total;
//
//   const agt25_updown = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(5, 5 / 2),
//     };
//   });
//   const agt25_neutral = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(5, 5 / 2),
//     };
//   });
//   const agt25_total = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: (agt25_updown[i - agg_range[0]].value + agt25_neutral[i - agg_range[0]].value) / 2,
//     };
//   });
//   AGGREGATED_GAINS_TOP25.updown = agt25_updown;
//   AGGREGATED_GAINS_TOP25.neutral = agt25_neutral;
//   AGGREGATED_GAINS_TOP25.total = agt25_total;
//
//   const ar_updown = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(100000, 100000 / 2),
//     };
//   });
//
//   const ar_neutral = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(100000, 100000 / 2),
//     };
//   });
//
//   const ar_total = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: ar_updown[i - agg_range[0]].value + ar_neutral[i - agg_range[0]].value,
//     };
//   });
//   AGGREGATED_REWARDS.updown = ar_updown;
//   AGGREGATED_REWARDS.neutral = ar_neutral;
//   AGGREGATED_REWARDS.total = ar_total;
//
//   const as_updown = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(1000000, 0),
//     };
//   });
//   const as_neutral = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: randomValue(1000000, 0),
//     };
//   });
//
//   const as_total = agg_range.map((i) => {
//     return {
//       cycle: i,
//       value: as_updown[i - agg_range[0]].value + as_neutral[i - agg_range[0]].value,
//     };
//   });
//
//   AGGREGATED_STAKES.updown = as_updown;
//   AGGREGATED_STAKES.neutral = as_neutral;
//   AGGREGATED_STAKES.total = as_total;
//
//   AGGREGATED_ALLOCATIONS.updown = Array.from({ length: 30 }).flatMap(() =>
//     ASSETS.map((asset) => {
//       return {
//         asset: asset,
//         percentage: 'ABCDE'.includes(asset.slice(0, 1))
//           ? randomValue(0.4, -5)
//           : 'GHIJK'.includes(asset.slice(0, 1))
//           ? randomValue(0.12, -2)
//           : randomValue(10, 3),
//         gain: 0,
//       };
//     }),
//   );
//
//   AGGREGATED_ALLOCATIONS.neutral = Array.from({ length: 30 }).flatMap(() =>
//     ASSETS.map((asset) => {
//       return {
//         asset: asset,
//         percentage: randomValue(0.2, 0.1),
//         gain: 0,
//       };
//     }),
//   );
//
//   const data = productPackage.find((product) => product.product === 1);
//   if (data) {
//   }
// }

// initCompetitionData();

function getRenamedMarket(market: string): string {
  const CHAIN_RENAMES: { [key: string]: string } = {
    'BSC via 1Inch': 'BSC',
    'Optimism via Synthetix': 'Optimism',
    'Solana via Jupiter': 'Solana',
  };
  return CHAIN_RENAMES[market] || 'Unknown Market';
}

const skippedMarkets = ['Binance CEX', 'Unknown Market'];

export async function getFullMarketplaceData(): Promise<MarketplaceData[]> {
  const mpData = await backend.getMarketplaceTableData();
  const size = mpData.length;
  const marketplaceData: MarketplaceData[] = [];
  for (let i = 0; i < size; i++) {
    const data = mpData[i];
    const market = getRenamedMarket(data['market']);
    if (skippedMarkets.includes(market)) {
      continue;
    }
    marketplaceData.push({
      market: market,
      metamodel: data['metamodel'],
      product: data['productId'],
      returns: 0,
      cagr: 0,
      sharpe: 0,
      calmar: 0,
      maxDrawdown: 0,
      name: data['name'],
      strategy: data['strategy'],
      h24: Number(data['returns24h']) * 100,
      d7: Number(data['returns7d']) * 100,
      d30: Number(data['returns30d']) * 100,
      m3: Number(data['returns3m']) * 100,
      y1: Number(data['returns1y']) * 100,
      total: Number(data['returnsAll']) * 100,
      addedTimestamp: new Date(data['addedTimestamp']).getTime(),
      visitCount: data['visitCount'],
    });
  }
  return marketplaceData.sort((a, b) => b.total - a.total);
}

// returns MarketplaceData[]
export function getMarketplaceData(
  marketplaceInput: MarketplaceData[],
  selectedCBs: { [key in CB]: boolean },
): MarketplaceData[] {
  // const marketplaceInput = await getFullMarketplaceData();
  // iterate through marketplacePackage and return an array of MarketplaceData
  const size = marketplaceInput.length;
  const marketplaceData: MarketplaceData[] = [];

  for (let i = 0; i < size; i++) {
    const data = marketplaceInput[i];

    if (!selectedCBs[CB.ethChain]) {
      if (data.market.toUpperCase().includes('ETH')) {
        continue;
      }
    }

    if (!selectedCBs[CB.bnbChain]) {
      if (data.market.toUpperCase().includes('BSC')) {
        continue;
      }
    }

    if (!selectedCBs[CB.opChain]) {
      if (data.market.toUpperCase().includes('OP')) {
        continue;
      }
    }

    if (!selectedCBs[CB.cex]) {
      if (data.market.toUpperCase().includes('CEX')) {
        continue;
      }
    }

    if (!selectedCBs[CB.uniswap]) {
      if (data.market.toUpperCase().includes('UNISWAP')) {
        continue;
      }
    }

    if (!selectedCBs[CB.oneinch]) {
      if (data.market.toUpperCase().includes('1INCH')) {
        continue;
      }
    }

    if (!selectedCBs[CB.synthetix]) {
      if (data.market.toUpperCase().includes('SYNTHETIX')) {
        continue;
      }
    }

    if (!selectedCBs[CB.binance]) {
      if (data.market.toUpperCase().includes('BINANCE')) {
        continue;
      }
    }

    if (!selectedCBs[CB.okx]) {
      if (data.market.toUpperCase().includes('OKX')) {
        continue;
      }
    }

    if (!selectedCBs[CB.solanaChain]) {
      if (data.market.toUpperCase().includes('SOL')) {
        continue;
      }
    }

    if (!selectedCBs[CB.jupiter]) {
      if (data.market.toUpperCase().includes('JUPITER')) {
        continue;
      }
    }

    // if (cexDexSelector.toUpperCase() == 'CEX') {
    //   if (!data.market.toUpperCase().includes('CEX')) {
    //     continue;
    //   }
    // } else if (cexDexSelector.toUpperCase() == 'DEX') {
    //   if (data.market.toUpperCase().includes('CEX')) {
    //     continue;
    //   }
    // } else if (cexDexSelector.toUpperCase() != 'ALL') {
    //   if (!data.market.toUpperCase().includes(cexDexSelector)) {
    //     continue;
    //   }
    // }
    marketplaceData.push(data);
  }

  return marketplaceData.sort((a, b) => b.total - a.total);
}

export const EMPTY_PRODUCT_DATA: ProductData = {
  market: '',
  metamodel: '',
  product: 'product does not exist',
  returns: 0,
  cagr: 0,
  sharpe: 0,
  calmar: 0,
  maxDrawdown: 0,
  historicalReturns: {
    '1d': [],
    '1w': [],
    '1m': [],
    '3m': [],
    '1y': [],
    all: [],
  },
  predictors: [],
  aiStage: 0,
  yiedlStaked: 0,
  rewardBurnRatio: 0,
  name: '',
  strategy: '',
};

export const EMPTY_COMPETITION_DATA: CompetitionData = {
  competition: 'updown',
  address: '',
  latestRelativeGain: 0,
  rewardBurnRatio: 0,
  currentStake: 0,
  historicalRewards: [],
  historicalRelativeGains: [],
  historicalAllocations: [],
  aiAge: 0,
  cagr: 0,
};
//
// export async function getParticipantAllocation(
//   competition: string,
//   address: string,
//   cycle: number,
// ): Promise<Allocation[]> {
//   const data = COMPETITON_DATA.find(
//     (competitionData) => competitionData.competition === competition && competitionData.address === address,
//   );
//   if (!data) {
//     return [];
//   }
//
//   return cycle < data.historicalAllocations.length ? data.historicalAllocations[cycle] : [];
// }

export async function getProductData(productId: number): Promise<ProductData> {
  // find the product with the given productId in marketplacePackage and return a ProductData object
  console.log('==== get product data: productId: ', productId);

  const data2 = await backend.getProductData(productId.toString());

  console.log('=====> getProductData: data: ', data2);

  const data = data2;
  if (!data) {
    return EMPTY_PRODUCT_DATA;
  }

  // get a list of address attributes from COMPETITION_DATA
  // const addresses = COMPETITON_DATA.map((competitionData) => competitionData.address);
  // const preds = data.predictors;
  // for (let i = 0; i < preds.length; i++) {
  //   preds[i].predictor = addresses[i];
  // }

  const h24_returns = convertListToDatapoints(data['returns24h']);
  const h24_return_value = h24_returns[h24_returns.length - 1].value;

  return {
    market: data['market'],
    metamodel: data['metamodel'],
    product: data['productId'],
    returns: h24_return_value,
    cagr: 0,
    sharpe: Number(data['sharpe']),
    calmar: 0,
    maxDrawdown: Number(data['maxDrawdown']) * 100,
    historicalReturns: {
      '1d': h24_returns,
      '1w': convertListToDatapoints(data['returns7d']),
      '1m': convertListToDatapoints(data['returns30d']),
      '3m': convertListToDatapoints(data['returns3m']),
      '1y': convertListToDatapoints(data['returns1y']),
      all: convertListToDatapoints(data['returnsAll']),
    } as HistoricalReturns,
    predictors: data['sharesPredictors'].map((item: { predictor: string; shares: string }) => {
      return {
        predictor: item['predictor'],
        share: Number(item['shares']),
      };
    }),

    aiStage: Number(data['aiStage']),
    yiedlStaked: Number(data['staked']),
    rewardBurnRatio: Number(data['rewardBurnRatio']),
    name: data['name'],
    strategy: data['strategy'],
  };
}

async function recordClick(productId: number, inputAmount: number, connectedUser: string): Promise<void> {
  // record the click event in the database
  await backend.setClickRecord(productId, connectedUser.toLowerCase());
  console.info(`CLICK! User ${connectedUser} clicked on product ${productId} with value ${inputAmount}.`);
}

export async function calculateProductAllocation(
  productId: number,
  inputAmount: number,
  connectedUser: string,
): Promise<ProductAllocationActions[]> {
  await recordClick(productId, inputAmount, connectedUser);
  const data = await backend.getLatestAllocation(productId.toString());

  if (!data) {
    return [];
  }

  // console.log("==== latest allocation data: ", data);

  const actions: ProductAllocationActions[] = [];
  const totalAmount = 100;
  for (let i = 0; i < data.length; i++) {
    if (Number(data[i]['allocation']) == 0) {
      continue;
    }
    actions.push({
      asset: data[i]['asset'],
      action: data[i]['asset'] == 'USDC' ? 'hold' : data[i]['allocation'] > 0 ? 'buy' : 'sell',
      usdValue: totalAmount * Math.abs(data[i]['allocation']),
      percentage: Number(data[i]['allocation']) * 100,
    });
  }

  if (actions.length == 0) {
    actions.push({
      asset: 'not applicable',
      action: 'hold',
      usdValue: totalAmount,
      percentage: -1,
    });
  }

  return actions;
}

function simpleHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

// not used
export async function getHistoricalRelativeGains(): Promise<CompetitionDataPoint[][][]> {
  // all objects where compeittion is 'neutral'
  const neutralData = COMPETITON_DATA.filter((data) => data.competition == 'neutral');
  const neutralDataPoints: CompetitionDataPoint[][] = [];
  for (let i = 0; i < neutralData.length; i++) {
    neutralDataPoints.push(neutralData[i].historicalRelativeGains);
  }

  // all objects where compeittion is 'updown'
  const updownData = COMPETITON_DATA.filter((data) => data.competition == 'updown');
  const updownDataPoints: CompetitionDataPoint[][] = [];
  for (let i = 0; i < updownData.length; i++) {
    updownDataPoints.push(updownData[i].historicalRelativeGains);
  }

  return [neutralDataPoints, updownDataPoints];
}

export interface AssetBullishIndex {
  asset: string;
  bullishIndex: number;
}

export async function getAssetBullishIndex(): Promise<AssetBullishIndex[]> {
  const res = await backend.getAssetStrengths();
  const excludedAssets = ['USD', 'USDC', 'USDT'];
  return res
    .filter((data: any) => !excludedAssets.includes(data['asset']))
    .map((data: any) => {
      return {
        asset: data['asset'],
        bullishIndex: Number(data['strength']) * 100,
      };
    })
    .sort((a: AssetBullishIndex, b: AssetBullishIndex) => b.bullishIndex - a.bullishIndex);
}

function strengthHasher(seed: string): number {
  const rng = seedrandom(seed);
  const negative = rng() > 0.5 ? 1 : -1;
  return Number((10 * rng() * negative).toFixed(1));
}

function deterministicRandomHistoryList(seed: number, length = 100): number[] {
  const res = [];
  let runningVal = seed;
  for (let i = 0; i < length - 1; i++) {
    const newVal = strengthHasher(runningVal.toFixed(2));
    res.push(newVal);
    runningVal += newVal * i;
  }
  res.push(seed / 10);
  return res;
}

export async function getStrengthHistory(asset: string): Promise<CompetitionDataPoint[]> {
  const assetBullishIndex: number =
    assetsBullishIndexPackage.find((data) => data.asset === asset.toUpperCase())?.bullishIndex || 0;
  const indexHistory = deterministicRandomHistoryList(assetBullishIndex);
  return indexHistory.map((value, index) => {
    return {
      cycle: index + 1,
      value: value,
    };
  });
}

export const EXCHANGE_LINKS: { [key: string]: string } = {
  'BSC via 1Inch': 'https://app.1inch.io/#/56/simple/swap/USDC',
  'BSC-Uniswap': 'https://app.uniswap.org/swap?chain=bnb',
  'Binance CEX': 'https://www.binance.com/en/markets/overview',
  'CEX-OKX': 'https://www.okx.com/markets/prices',
  'ETH-1inch': 'https://app.1inch.io/#/1/simple/swap/USDC',
  'ETH-Uniswap': 'https://app.uniswap.org/swap?chain=mainnet',
  'OP-1inch': 'https://app.1inch.io/#/10/simple/swap/USDC',
  'Optimism via Synthetix': 'https://trade.polynomial.fi/markets',
  'OP-Uniswap': 'https://app.uniswap.org/swap?chain=optimism',
  'Solana via Jupiter': 'https://jup.ag',
};

const TOKEN_LOGOS: { [key: string]: any } = {};

ASSETS.forEach((asset) => {
  TOKEN_LOGOS[asset] = require(`@app/assets/tokens/${asset.toLowerCase()}.png`).default;
});

['ARB', 'ZIL', 'USDC', 'ENJ'].forEach((asset) => {
  TOKEN_LOGOS[asset] = require(`@app/assets/tokens/${asset.toLowerCase()}.png`).default;
});

export { TOKEN_LOGOS };

export async function getCryptoMarketFeed(): Promise<MarketFeed[]> {
  const marketFeed: MarketFeed[] = [];
  const results = await backend.getPriceFeed();
  const excludedAssets = ['USDC', 'USDT', 'USD'];
  for (const result of results) {
    if (excludedAssets.includes(result.asset)) continue;
    marketFeed.push({
      symbol: result.asset,
      price: Number(result.price).toLocaleString(undefined, {
        minimumSignificantDigits: 4,
        maximumSignificantDigits: 4,
      }),
    });
  }
  marketFeed.sort((a, b) => a.symbol.localeCompare(b.symbol));
  return marketFeed;
}

export async function getBtcHistory(): Promise<HistoricalReturns> {
  const data = await backend.getBtcHistoricalReturns();
  console.log('==== btc data>>', data);
  return {
    '1d': convertListToDatapoints(data['returns24h']),
    '1w': convertListToDatapoints(data['returns7d']),
    '1m': convertListToDatapoints(data['returns30d']),
    '3m': convertListToDatapoints(data['returns3m']),
    '1y': convertListToDatapoints(data['returns1y']),
    all: convertListToDatapoints(data['returnsAll']),
  } as HistoricalReturns;
}

export function filterCompetitionData(
  competitionData: CompetitionTableData[],
  searchText: string,
): CompetitionTableData[] {
  if (!searchText) return competitionData;
  return competitionData.filter((data) => {
    return data.address.toLowerCase().includes(searchText.toLowerCase());
  });
}

export interface TokenContributor {
  address: string;
  ranking: number;
  side: 'long' | 'short';
}

export async function getTokenContributors(token: string): Promise<TokenContributor[]> {
  const allContributors = COMPETITON_DATA.slice(0, COMPETITON_DATA.length / 2).map((data) => {
    return {
      address: data.address,
      ranking:
        deterministicRandomValue(data.address, 1, COMPETITON_DATA.length / 2 + 10) + COMPETITON_DATA.length / 2 + 10,
      side: Math.abs(deterministicRandomValue(data.address, 0, 1)) == 0 ? 'long' : 'short',
    } as TokenContributor;
  });

  const numContributors = deterministicRandomValue(token, allContributors.length / 2, allContributors.length);
  return allContributors.slice(0, numContributors).sort((a, b) => a.address.localeCompare(b.address));
}

export function getNumLongsOrShorts(tokenContributors: TokenContributor[], side: 'long' | 'short'): number {
  return tokenContributors.filter((contributor) => contributor.side == side).length;
}

export interface DatasetDetails {
  startDate: string;
  endDate: string;
  samples: number;
  uniqueSymbols: number;
}

export interface DatasetInfo {
  weekly: DatasetDetails;
  daily: DatasetDetails;
  latest: DatasetDetails;
}

export const EMPTY_DATASET_INFO: DatasetInfo = {
  weekly: { startDate: '', endDate: '', samples: 0, uniqueSymbols: 0 },
  daily: { startDate: '', endDate: '', samples: 0, uniqueSymbols: 0 },
  latest: { startDate: '', endDate: '', samples: 0, uniqueSymbols: 0 },
};

export async function retrieveDatasetInfo(): Promise<DatasetInfo> {
  const datasetInfo = await backend.getDatasetInfo();
  return {
    weekly: {
      startDate: datasetInfo.weekly.startDate,
      endDate: datasetInfo.weekly.endDate,
      samples: datasetInfo.weekly.samples,
      uniqueSymbols: datasetInfo.weekly.uniqueSymbols,
    },
    daily: {
      startDate: datasetInfo.daily.startDate,
      endDate: datasetInfo.daily.endDate,
      samples: datasetInfo.daily.samples,
      uniqueSymbols: datasetInfo.daily.uniqueSymbols,
    },
    latest: {
      startDate: datasetInfo.latest.startDate,
      endDate: datasetInfo.latest.endDate,
      samples: datasetInfo.latest.samples,
      uniqueSymbols: datasetInfo.latest.uniqueSymbols,
    },
  };
}
